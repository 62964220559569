import { Loading } from "@nextui-org/react";
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

//IMPORT PAGES
import Dashboard from "../Dashboard/index";
import Users from "../Users/index";
import Login from "../Login/index";
import Teams from "../Teams";
import Report from "../Report";
import Notification from "../Notification";
import Location from "../Location/index";
import ForgotPassword from "../ForgotPassword";
import Courts from "../Courts";
import AddCourt from "../Courts/addCourt";

const Router = () => {
  const AuthToken = useSelector((state) => state.auth.authToken);
  return (
    <>
      <Routes>
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        {!AuthToken ? (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            element={<Login />}
          />
        ) : (
          <Route exact path={`${process.env.PUBLIC_URL}/`} element={<Dashboard />} />
        )}
        {/* <Route exact path='/' element={<Login />}/> */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dashboard`}
          element={<Dashboard />}
        />
        <Route exact path={`${process.env.PUBLIC_URL}/users`} element={<Users />} />
        <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        <Route exact path={`${process.env.PUBLIC_URL}/teams`} element={<Teams />} />
        <Route exact path={`${process.env.PUBLIC_URL}/reports/:id`} element={<Report />} />
        <Route exact path={`${process.env.PUBLIC_URL}/notification`} element={<Notification />} />
        <Route exact path={`${process.env.PUBLIC_URL}/location`} element={<Location />} />
        <Route exact path={`${process.env.PUBLIC_URL}/api/V1/user/forgot_password/:id`} element={<ForgotPassword />} />
        <Route exact path={`${process.env.PUBLIC_URL}/courts`} element={<Courts />} />
        <Route exact path={`${process.env.PUBLIC_URL}/add-courts`} element={<AddCourt />} />

        {/* </Suspense> */}
      </Routes>
    </>
  );
};

export default Router;
