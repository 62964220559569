import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const GeoLocation = ({ google, passDataToParent, showMapModal, setShowMapModal }) => {
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState({});
    const [selectedPlace, setSelectedPlace] = useState({});
    const [location, setLocation] = useState({});
    const [courtAddress,setCourtAddress] = useState();

    const onMarkerClick = (props, marker) => {
        console.log("props", props);
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    };
    const onMapClick = (props, marker, clickEvent) => {
        const reverseGeocodingUrl = `https://api.geoapify.com/v1/geocode/reverse?lat=${clickEvent.latLng.lat()}&lon=${clickEvent.latLng.lng()}&apiKey=d6d98f7a399143bd949c60f4f8b33af2`;
                    // const address='';
                    fetch(reverseGeocodingUrl)
                      .then((result) => result.json())
                      .then((featureCollection) => {
                        // setAddress(
                        //   featureCollection.features[0].properties.formatted
                        // );
                        setCourtAddress(featureCollection.features[0].properties.formatted);
                        console.log("Address",featureCollection.features[0].properties.formatted);
                      });
        console.log("props", props?.initialCenter);
        setLocation({
            lat: clickEvent.latLng.lat(),
            lng: clickEvent.latLng.lng(),
        });

        if (showingInfoWindow) {
            setShowingInfoWindow(false);
            setActiveMarker(null);
        }
    };

    console.log("location", location);

    const handleData = () =>{
        setShowMapModal(false);
        passDataToParent(location,courtAddress);
    }

    return (
        <div>
            <Modal
                destroyOnClose={true}
                open={showMapModal}
                centered
                width={900}
                onCancel={() => setShowMapModal(false)}
                footer={null}
            >
                <div>
                    <div style={{ height: 700, position: "relative" }}>
                        <Map google={google} onClick={onMapClick} disableDefaultUI={true}>
                            {location && courtAddress &&
                                <Marker onClick={onMarkerClick} name={`${courtAddress}`} position={location} />}

                            <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                                <div>
                                    <h2>{selectedPlace.name}</h2>
                                </div>
                            </InfoWindow>
                        </Map>
                    </div>
                    <div>
                        <button className='select-location' onClick={handleData}>Select</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyCPvq_YGJQQKoeoEcZw3p-5aPddmENN-Lw'
})(GeoLocation);
// export default GeoLocation

