import { Row, Col, Image, Input, notification, Spin } from "antd";
import React, { useState } from "react";
import { Formik } from "formik";
import LoginSideImage from "../../Assets/Images/login-sideImg-2.jpg";
import logo from "../../Assets/Images/cr-logo 1.png";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../../Graphs/Auth/login";
import { useDispatch } from "react-redux";
import {
  setAuthToken,
  setLoginFlag,
  setLoginUserData,
} from "../../actions/auth";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const validateForm = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = "username is required";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password !== "" && values.password.length < 5) {
      errors.password = "At least 5 characters required";
    }

    console.log("Validating errors -- ", errors);

    return errors;
  };

  // LOGIN API CALL
  const doLogin = async (values) => {
    try {
      setFormSubmitting(true);
      await adminLogin(values).then((res) => {
        if (res) {
          console.log("Success", res);
          notification["success"]({
            message: "Logged In Successfully",
          });
          navigate(`${process.env.PUBLIC_URL}/dashboard`);
          dispatch(setLoginFlag(true));
          dispatch(setLoginUserData(res));
          dispatch(setAuthToken(res?.newToken));
          setFormSubmitting(false);
        } else {
          console.log("Error in Login API", res);
          setFormSubmitting(false);
        }
      });
    } catch (e) {
      console.log("Error in Login --", e);
      notification["error"]({
        message:
          typeof e === "string"
            ? e
            : typeof e.message === "string"
            ? e.message
            : "Invalid Login Credentials",
      });
      setFormSubmitting(false);
      return e;
    }
  };
  const handleLoginFormAction = (values, { resetForm }) => {
    doLogin(values);
  };
  return (
    <div className="login-container">
      <Row justify="space-around">
        <Col span={15}>
          <Image
            preview={false}
            width={"100%"}
            height="100vh"
            src={LoginSideImage}
            alt="sideImage"
          />
        </Col>
        <Col span={9}>
          <div className="lg-conatiner" >
            {/* {formSubmitting ? (
              <Spin spinning={formSubmitting} />
            ) : (
              <> */}
                <div className="flex lg-header">
                  <div className="flex pb-4">
                    <Image
                      preview={false}
                      src={logo}
                      width={85}
                      className="cr-logo1"
                    />
                    <span className="flex items-center head-text pl-3 pt-3">
                      Court Royale
                    </span>
                  </div>
                  <h4 className="flex lg-text">Login</h4>
                </div>
                <div className="mt-2">
                  <Formik
                    initialValues={{
                      username: "",
                      password: "",
                    }}
                    validate={validateForm}
                    onSubmit={handleLoginFormAction}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      setFieldTouched,
                      isSubmitting,
                    }) => {
                      return (
                        <form
                          name="loginForm"
                          className="loginForm"
                          action="#"
                          method="POST"
                        >
                          <div className="formik-field_wrap row auth_form pb-3">
                            <div className="formik-field-left col col-12 auth_field">
                              Username
                              <Input
                                style={{ padding: "10px 15px", width: "90%" }}
                                id="username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control username"
                                autoComplete="off"
                              />
                              {errors.username && touched.username ? (
                                <p
                                  style={{
                                    display: "flex",
                                    marginBottom: "24px",
                                    color: "red",
                                    fontSize: "small",
                                    margin: "0",
                                  }}
                                >
                                  {errors.username}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="formik-field_wrap row auth_form">
                            <div className="formik-field-left col col-12 auth_field">
                              Password
                              <Input.Password
                                style={{ padding: "10px 15px", width: "90%" }}
                                type="password"
                                id="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control password"
                                autoComplete="off"
                              />
                              {errors.password && touched.password ? (
                                <p
                                  style={{
                                    display: "flex",
                                    marginBottom: "24px",
                                    color: "red",
                                    fontSize: "small",
                                    margin: "0",
                                  }}
                                >
                                  {errors.password}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group mt-3">
                            <button
                              className="btn_login"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Login
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              {/* </>
            )} */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
