import { PUSH_NOTIFICATION } from "../constant/comman";
import axios from "axios";

export const sendNotification = async (Value) => {
    try {
        return await axios.post(`${PUSH_NOTIFICATION}`, {
            "message": Value,
        });
      
    }
    catch (error) {
      throw error;
    }
  };
