import {GET_REPORTS,GET_REPORT_BY_ID} from "../constant/comman";
import axios from "axios";

export const getReports = async (AuthToken) => {
    try {
        return await axios.get(`${GET_REPORTS}`, {
        headers: { Authorization: `Bearer ${AuthToken}` },
        });
      
    }
    catch (error) {
      throw error;
    }
  };


  export const getReportById = async (AuthToken,Id) => {
    try {
        return await axios.get(`${GET_REPORT_BY_ID}/${Id}`, {
        headers: { Authorization: `Bearer ${AuthToken}` },
        });
      
    }
    catch (error) {
      throw error;
    }
  };