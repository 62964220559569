import React from "react";
import { Formik } from "formik";
import { Input, Modal } from "antd";
import { notification } from "antd";
import { updateUsers } from "../../Graphs/getUser";
import { useSelector } from "react-redux";
import { getUsers } from "../../Graphs/getUser";
import { useDispatch } from "react-redux";
import { setUser } from "../../actions/users";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth";

const UpdateUser = ({
  showUpdateModal,
  setShowUpdateModal,
  userData,
  setUserListUpdated,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthToken = useSelector(
    (state) => state?.auth?.loginUserData?.newToken
  );
  const validateForm = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = " First Name is required";
    } else if (!/^[a-zA-Z]+$/.test(String(values.first_name))) {
      errors.first_name = "Enter text only";
    }

    if (!values.last_name) {
      errors.last_name = "Last Name is required";
    } else if (!/^[a-zA-Z]+$/.test(String(values.last_name))) {
      errors.last_name = "Enter text only";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        String(values.email).toLowerCase()
      )
    ) {
      errors.email = "Please enter a valid email id";
    }

    if (!values.phone) {
      errors.phone = "Phone Number is required";
    } else if (!/^[789]\d{9}$/.test(values.phone)) {
      errors.phone = "Please enter a valid number";
    }

    console.log(values.phone);
    return errors;
  };

  // UPDATE USER DETAILS
  const updateUserDetail = async (Id, values) => {
    await updateUsers(AuthToken, Id, values)
      .then((res) => {
        if (res) {
          // getUserData();
          console.log("update Res", res);
          notification["success"]({
            message: "User Updated Successfully",
          });
          setShowUpdateModal(false);
          setUserListUpdated(true);
        }
      })
      .catch((e) => {
        console.log("!!! Error in update User !!!", e);
        if (e.status === 500) {
          notification["error"]({
            message:
              typeof e === "string"
                ? e
                : typeof e.message === "string"
                ? e.message
                : "Invalid Details",
          });
        } else {
          dispatch(logout());
          navigate(`${process.env.PUBLIC_URL}/`);
        }

        return e;
      });
  };

  // GET UPDATED USER LIST
  const getUserData = async () => {
    await getUsers(AuthToken)
      .then((res) => {
        dispatch(setUser(res));
        setShowUpdateModal(false);
        setUserListUpdated(true);
      })
      .catch((e) => {
        console.log("!!! Error in getUser !!!", e);
        dispatch(logout());
        navigate(`${process.env.PUBLIC_URL}/`);

        return e;
      });
  };
  const handleSubmit = (values) => {
    updateUserDetail(userData?._id, values);
  };
  const string = userData?.full_name.split(" ");

  return (
    <Modal
      destroyOnClose={true}
      open={showUpdateModal}
      centered
      width={700}
      onCancel={() => setShowUpdateModal(false)}
      footer={null}
    >
      <div className="update-form">
        <Formik
          initialValues={{
            first_name: string[0],
            last_name: string[1],
            email: userData?.email,
            phone: userData?.phone_number,
          }}
          validate={validateForm}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            return (
              <div className=" p-3 px-4">
                <div className="modal_heading text-center mb-3">
                  <h4 className="update-heading mb-2" id="exampleModalLabel">
                    Update User
                  </h4>
                </div>
                <div className="formik-field_wrap row auth_form my-2">
                  <div className="formik-field-left col col-lg-6 col-12 auth_field">
                    First Name
                    <Input
                      style={{ padding: "10px 15px" }}
                      type="text"
                      id="first_name"
                      placeholder="First Name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.first_name && touched.first_name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.first_name}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right col col-lg-6 col-12 auth_field">
                    Last Name
                    <Input
                      style={{ padding: "10px 15px" }}
                      type="text"
                      id="last_name"
                      placeholder="Last Name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.last_name && touched.last_name ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.last_name}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="formik-field_wrap row auth_form my-2">
                  <div className="formik-field-left col col-lg-6 col-12 auth_field">
                    Email
                    <Input
                      style={{ padding: "10px 15px" }}
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.email}
                      </p>
                    ) : null}
                  </div>

                  <div className="formik-field-right col col-lg-6 col-12 auth_field">
                    Phone
                    <div className="input-group col col-12 col-sm-12">
                      <Input
                        // international
                        id="phone"
                        type="number"
                        style={{ padding: "10px 15px" }}
                        countryCallingCodeEditable={false}
                        defaultCountry="IN"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="col-lg-12 col-sm-12"
                        maxLength={12}
                      />
                    </div>
                    {errors.phone && touched.phone ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "small",
                          margin: "0",
                        }}
                      >
                        {errors.phone}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="col col-12 text-center mt-4">
                  <button
                    type="submit"
                    className="update-user-btn "
                    data-bs-toggle="modal"
                    data-bs-target="#OtpModal"
                    onClick={handleSubmit}
                  >
                    <i className="fa-solid fa-circle-check me-2"></i>Update User
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpdateUser;
