import { LOGIN, SOCIAL_LOGIN } from "../../constant/comman";
import axios from "axios";

export const adminLogin = async (values) => {
  try {
      return await axios.post(`${LOGIN}`, {
        email: values.username,
        password: values.password,
      });
    
  }
  catch (error) {
    throw error;
  }
};