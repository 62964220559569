export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_AUTHTOKEN = "SET_USER_AUTHTOKEN";
export const USER_LOGOUT = "USER_LOGOUT";

export const SET_USERS = "SET_USERS";
export const SET_TEAMS = "SET_TEAMS";
export const SET_REPORTS = "SET_REPORTS";

export const TOGGLE_BTN = "SET_REPORTS";

export const COURT_DATA = "COURT_DATA"; 