export const REACT_APP_API_KEY = `AIzaSyCPvq_YGJQQKoeoEcZw3p-5aPddmENN-Lw`;

export const WEBURL = "http://courtroyale.prometteur.in:3335/"; // Test Server
// export const WEBURL = "http://courtroyaleserver.xyz:4011/";     // Live Server


const VERSION = "v1";

// Authentication
export const LOGIN = `${WEBURL}admin/login`;
export const LOGOUT = `${WEBURL}admin/logout`;

// User
export const GET_USER = `${WEBURL}admin/getUsers`;

export const DELETE_USER= `${WEBURL}admin/deleteUser`;

export const GRAPH_DATA = `${WEBURL}admin/getUsers_forGraph`;
// export const DELETE_MULTIPLE = `${WEBURL}admin/deleteAllUsers`;

export const UPDATE_USER = `${WEBURL}admin/updateUser`;

export const SERCH_USER = `${WEBURL}admin/searchUsers`;

export const USER_LISTING = `${WEBURL}admin/searchUsers`;

// Teams
export const GET_TEAMS = `${WEBURL}admin/getTeams`;

// Report
export const GET_REPORTS = `${WEBURL}admin/getReports`;

export const GET_REPORT_BY_ID = `${WEBURL}admin/getReport`;

export const SEARCH_TEAM = `${WEBURL}admin/searchTeams`;

export const LOCATION_DATA = `${WEBURL}admin/locationwise_shedule`;

//  Push Notification

export const PUSH_NOTIFICATION = `${WEBURL}admin/notification`;

export const USER_RESET_PASSWORD = `${WEBURL}api/V1/user/forgot_password`


//  Add courts

export const ADD_COURT = `${WEBURL}admin/add_park`;

export const GET_COURTS = `${WEBURL}admin/view_all_parks`;

export const UPLOAD_IMAGE = `${WEBURL}api/V1/team/profile-image`;

export const DELETE_COURT = `${WEBURL}admin/delete_park`;

