import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import HocComponent from "../../HOC";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth";
import { getReportById } from "../../Graphs/getReports";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { getReports } from "../../Graphs/getReports";
import { setReports } from "../../actions/reports";

const Report = () => {
  const ReportData = useSelector((state) => state?.reports?.reports);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const AuthToken = useSelector(
    (state) => state?.auth?.loginUserData?.newToken
  );
  const lastId = ReportData[ReportData.length - 1];
  const [reportData, setReportData] = useState();
  const [reports, setReport] = useState(ReportData);
  const [active, setActive] = useState(param.id);
  const [loadingReport, setLoadingReport] = useState(false);

  // GET REPORT DATA BY ID
  const getReportDetail = async (Id) => {
    setLoadingReport(true);
    await getReportById(AuthToken, Id)
      .then((res) => {
        if (res) {
          setReportData(res);
        }
        setLoadingReport(false);
      })
      .catch((e) => {
        console.log("!!! Error in getReport !!!", e);
        dispatch(logout());
        navigate(`${process.env.PUBLIC_URL}/`);
        setLoadingReport(false);
        return e;
      });
  };

  // GET REPORTS
  const getReportData = async () => {
    setLoadingReport(true);
    await getReports(AuthToken)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setReports(res.reports));
        }
        setLoadingReport(false);
      })
      .catch((e) => {
        console.log("!!! Error in getReport !!!", e);
        // dispatch(logout());
        // navigate(`${process.env.PUBLIC_URL}/`);
        setLoadingReport(false);
        return e;
      });
  };

  const handlereport = (Id) => {
    getReportDetail(Id);
    navigate(`${process.env.PUBLIC_URL}/reports/${Id}`);
    setActive(Id);
  };

  const onSetvalues = (e) => {
    const data = ReportData.filter((ele) => {
      return ele?.sender?.full_name.toLowerCase().includes(e.target.value);
    });

    setReport(data);
  };

  useEffect(() => {
    getReportDetail(lastId?.report?._id);
    getReportData();
  }, []);
  return (
    <>
      <div className="p-4">
        <div className="top-heading mx-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="cr-heading">
              <h4>Report</h4>
            </div>
          </div>
        </div>

        <div className="mx-3">
          <div className="row">
            <div className="col-5 pe-0 overflow-auto">
              <div className="mail-list-container">
                <div className="search-mail">
                  <div className="d-flex align-items-center justify-content-between position-relative">
                    <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                    <input
                      type="text"
                      className="search-input-report m-2"
                      placeholder="Search..."
                      onChange={(e) => onSetvalues(e)}
                    />
                  </div>
                </div>
                {reports && reports.length >= 1 ? (
                  <div className="mail-list">
                    <ul>
                      {reports.map((data) => {
                        return (
                          <>
                            <li key={data?.report?._id}>
                              <a
                                href="#"
                                className={`d-flex justify-content-between ${active == data?.report?._id ? "active" : ""
                                  }`}
                                onClick={() => handlereport(data?.report?._id)}
                              >
                                <div>
                                  <p>There's new Activity in App</p>
                                  <h6>{data?.sender?.full_name}</h6>
                                </div>
                                <span>
                                  {active == data?.report?._id ? (
                                    <div className="text-left">
                                      {moment(data?.report?.updatedAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                      <br />
                                      {moment(data?.report?.updatedAt).format(
                                        "LT"
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {moment(data?.report?.updatedAt)
                                        .format("dddd")
                                        .substring(0, 3)}{" "}
                                      {moment(data?.report?.updatedAt).format(
                                        "LT"
                                      )}
                                    </>
                                  )}
                                </span>
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <h2 style={{ marginTop: "20px" }}>Reports not found </h2>
                )
                }
              </div>
            </div>

            <div className="col-7 ps-0">
              <div className="mail-details">
                {loadingReport ? (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "25px",
                    }}
                  >
                    {" "}
                    <Spin />{" "}
                  </div>
                ) : (
                  reportData && (
                    <>
                      {/* <h3 className="mail-title">
                Technical Issue in the Application.
              </h3> */}
                      <div
                        className="d-flex py-3"
                        key={reportData?.report?._id}
                      >
                        <img
                          src={reportData?.sender?.profile_picture}
                          alt=""
                          // srcset=""
                          className="profile-icon"
                        />
                        <div className="ms-3 d-flex align-items-center">
                          <p className="mb-0">
                            From{" "}
                            <span className="mailid">
                              {reportData?.report?.email}
                            </span>{" "}
                            on{" "}
                            {moment(reportData?.report?.updatedAt).format(
                              "DD/MM/YYYY LT"
                            )}
                          </p>
                          {/* <div className="dropdown">
                        <a
                          className=" dropdown-toggle detail-dropdown"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Detail
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <p>email in detail</p>
                        </div>
                      </div> */}
                        </div>
                      </div>

                      <p className="py-4">Hello Sir/ Madam,</p>
                      <p>{reportData?.report?.description}</p>

                      <div className="pt-4">
                        <p>
                          --- <br />
                          Best Regards, <br />
                          {reportData?.sender?.full_name} <br />
                          {`+91 ${reportData?.report?.contact}`}
                        </p>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HocComponent(Report);
