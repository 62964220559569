import { ADD_COURT, DELETE_COURT, GET_COURTS, UPLOAD_IMAGE } from "../constant/comman";
import axios from "axios";


export const getCourts = async (AuthToken) => {
    try {
        return await axios.get(`${GET_COURTS}`, {
            headers: { Authorization: `Bearer ${AuthToken}` },
        });

    }
    catch (error) {
        throw error;
    }
};

export const addNewCourt = async (value, AuthToken) => {
    console.log("value",value);
    try {
        return await axios.post(`${ADD_COURT}`, {
            location: [value.lng, value.lat],
            name: value.name,
            address: value.address,
            // rating: value.rating,
            open_time: value.open_time,
            close_time: value.close_time
        }, {
            headers: { Authorization: `Bearer ${AuthToken}` },
        });
    }
    catch (error) {
        throw error;
    }
}

export const uploadImage = async (formData, Id, AuthToken) => {

    try {
        return await axios.put(`${UPLOAD_IMAGE}`, {
            image: formData,
            park_id: Id
        }, {

            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${AuthToken}`
            },
        });

    }
    catch (error) {
        throw error;
    }
};

export const deletePark = async (AuthToken,Id) => {
    try {
      return await axios.delete(`${DELETE_COURT}/${Id}`, {
        headers: { Authorization: `Bearer ${AuthToken}` },
      });
    } catch (error) {
      throw error;
    }
  };