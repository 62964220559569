import { SET_REPORTS } from "../constant/actionTypes";

const initial_state = {
  reports: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_REPORTS:
      return { ...state, reports: action.data };

    default:
      return { ...state };
  }
};
