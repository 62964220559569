import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import HocComponent from "../../HOC";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactPaginate from "react-paginate";
import placeholderImage from "../../Assets/Images/placeholder.jpg"

const Teams = () => {
  const TeamData = useSelector((state) => state?.teams?.teams);
  const [teams, setTeams] = useState(TeamData);
  const [currentTeams, setCurrentTeams] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [usersOffset, setUsersOffset] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % TeamData.length;
    setUsersOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = usersOffset + 10;
    setCurrentTeams(TeamData.slice(usersOffset, endOffset));
    setPageCount(Math.ceil(TeamData.length / 10));
  }, [TeamData, usersOffset]);
  
  const onSetvalues = (e) => {
    const data = TeamData.filter((ele, index) => {
      return ele.team?.title.toLowerCase().includes(e.target.value);
    });

    setTeams(data);
  };
  return (
    <>
      <div className="my-4">
        <div className="top-heading mx-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="cr-heading">
              <h4>List of Teams</h4>
            </div>
            <div className="d-flex align-items-center justify-content-between position-relative team-list">
              <FontAwesomeIcon icon={faSearch} className="searchIcon-small" />
              <input
                type="text"
                className="search-input-team mx-2"
                placeholder="Search..."
                onChange={(e) => onSetvalues(e)}
              />
            </div>
          </div>
        </div>

        <div className="list-table mx-3">
          {currentTeams && currentTeams.length >= 1 ? (
            <table className="w-100">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Team</th>
                </tr>
              </thead>
              <tbody>
                {
                  currentTeams.map((data, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td className="p-1">
                            <div className="d-flex justify-content-center">
                              <img src={data?.team?.profile_image !== null ? data?.team?.profile_image : placeholderImage} alt="" />
                            </div>
                          </td>
                          <td className="p-1">
                            <div className="d-flex align-items-center justify-content-center">
                              {/* <img src={data?.team?.logo} alt="" /> */}
                              <p className="ms-2">{data?.team?.title}</p>
                            </div>
                          </td>
                          <td className="p-1 team-date">
                            <div className="d-flex justify-content-center">
                              {moment(data?.team?.createdAt).format("DD/MM/YYYY")}
                            </div>
                          </td>
                          <td className="p-1">
                            <div className="row d-flex justify-content-center">
                              <ul className="users col-12">
                                {data?.teamMembers &&
                                  data?.teamMembers.map((item) => {
                                    return (
                                      <>
                                        <li key={item?._id}>
                                          <img
                                            src={item?.profile_picture}
                                            alt=""
                                          // srcset=""
                                          />
                                        </li>
                                      </>
                                    );
                                  })}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                }
              </tbody>
            </table>
          ) : (
            <h2 style={{marginTop:"20px"}}>Teams not found </h2>
          )}
          {currentTeams && currentTeams.length >= 5 ? (
            <div className="pagination justify-content-end">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<<"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default HocComponent(Teams);
