import React from 'react';
import HocComponent from '../../HOC';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { deletePark, getCourts, uploadImage } from '../../Graphs/addCourt';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { Input, notification, Modal } from 'antd';
import ReactPaginate from "react-paginate";
import { setCourts } from '../../actions/courts';
const { confirm } = Modal;
const Courts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courtsList = useSelector((state) => state?.courts?.courts);

  const [courtsData, setCourtsData] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [usersOffset, setUsersOffset] = useState(0);
  const [court, setCourt] = useState(courtsList)
  const [currentCourts, setCurrentCourts] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const AuthToken = useSelector(
    (state) => state?.auth?.loginUserData?.newToken
  );

  useEffect(() => {
    getallCourts();
  }, [selectedFile]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % court.length;
    setUsersOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = usersOffset + 10;
    setCurrentCourts(court.slice(usersOffset, endOffset));
    setPageCount(Math.ceil(court.length / 10));
  }, [court, usersOffset]);



  const getallCourts = async () => {
    await getCourts(AuthToken)
      .then((res) => {
        console.log("response", res);
        setCourtsData(res);
        dispatch(setCourts(res));
        setCourt(res);
      }).catch((err) => {
        console.log("!! Error in get Courts !!", err);
      })
  }

  const handleAddCourt = () => {
    navigate("/add-courts");
  }

  const uploadParkImage = async (formData, id) => {

    setSelectedFile(false);

    await uploadImage(formData, id, AuthToken)
      .then((res) => {
        notification["success"]({
          message: "Profile image uploaded successfully",
        });
        setSelectedFile(true);
      }).catch((err) => {
        console.log("!! Error in Upload Image !!", err);
      })
  }

  const handleUpload = (fileObj, data) => {
    const file = fileObj.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    console.log("file", formData);
    uploadParkImage(file, data)

  }

  const deleteParkById = async (Id) => {
    await deletePark(AuthToken, Id)
      .then((res) => {
        console.log("Response", res);
        notification["success"]({
          message: "Court deleted successfully",
        });
        getallCourts();
      }).catch((err) => {
        console.log("!! error in deleting park !!");
      })
  }

  const showDeleteConfirm = (Id) => {
    confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Delete',

      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        console.log('OK');
        deleteParkById(Id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <div>
      <div className="my-4">
        <div className="top-heading mx-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="cr-heading">
              <h4>List of Courts</h4>
            </div>
            {/* <div className="d-flex align-items-center justify-content-between position-relative">
              <FontAwesomeIcon icon={faSearch} className="searchIcon-small" />
              <input
                type="text"
                className="search-input-team mx-2"
                placeholder="Search..."
                onChange={(e) => onSetvalues(e)}
              />
            </div> */}
          </div>
        </div>
        <div className='d-flex justify-content-end me-4'>
          <button className='add-court-btn' onClick={handleAddCourt}>+ Add Court</button>
        </div>

        <div className="list-table mx-3">
          {currentCourts && currentCourts.length >= 1 ? (
            <table className="w-100">
              <thead>
                <tr>
                  {/* <th className="d-flex align-items-center">
                  <input type="checkbox" className="form-check-input" />
                  <Checkbox
                    indeterminate={checkedList.length < userData.length && checkedList.length > 0}
                    onChange={onCheckAllChange}
                    checked={checkedList.length === userData.length}
                  >
                    Check all
                  </Checkbox>
                  <span className="ms-3">All</span>
                </th> */}
                  <th></th>
                  <th>Lattitude</th>
                  <th>Longitude</th>
                  <th>Name</th>
                  <th>Address</th>
                  {/* <th>Rating</th> */}
                  <th>Open time</th>
                  <th>Close time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  currentCourts &&
                  currentCourts
                  // .sort((a, b) => a.updatedAt
                  //   > b.updatedAt ? -1 : 1
                  // )
                    .map((data) => {
                      console.log("data", data);
                      return (
                        <>
                          <tr >
                            {/* <td>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            className="form-check-input"
                          />
                          <Checkbox option={data}
                           value={checkedList} onChange={onChange}>
                            {}
                          </Checkbox>
                        </td> */}
                            <td>
                              <div className="d-flex align-items-center court">

                                {data?.image && data?.image !== " " ?
                                  <img
                                    src={data?.image}
                                    alt=""
                                    className="profile-court"
                                  /> :
                                  <div className='cr-md-btn d-flex justify-content-center align-items-center'>
                                    Upload
                                    <Input type="file" className='file' onChange={(e) => handleUpload(e, data?._id)} placeholder="upload" />
                                  </div>
                                }
                              </div>
                            </td>
                            <td>
                              {data?.location[1]}
                              {/* lat */}
                            </td>
                            <td>
                              {data?.location[0]}
                              {/* long */}
                            </td>
                            <td className='d-flex'>
                              
                              {data?.name}
                              {!data?.is_park &&
                                <svg width="15" height="15" fill="#f92705" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M19 4H6V2H4v18H3v2h4v-2H6v-5h13a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Z"></path>
                                </svg>
                              }
                            </td>
                            <td className="user-email">{data?.address}</td>
                            {/* <td>{data?.rating}</td> */}
                            <td>{moment(data?.open_time).format('LT')}</td>
                            <td>{moment(data?.close_time).format('LT')}</td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                {/* <button
                                className="cr-md-btn"
                              // onClick={() => handleUpdateMOdal(data)}
                              >
                                Update
                              </button> */}
                                <button
                                  className="cr-del-btn ms-2"
                                  onClick={() => showDeleteConfirm(data?._id)}
                                >
                                  {" "}
                                  <FontAwesomeIcon icon={faTrash} className="" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                }
              </tbody>
            </table>
          ) : (
            <h2 style={{ marginTop: "20px" }}>Users Not Found</h2>
          )}
          {/* {court && court.length >= 1 ? ( */}
           <div className="pagination justify-content-end">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div> 
           {/* ) : null}  */}
        </div>

        {/* {showUpdateModal && (
          <UpdateUser
            showUpdateModal={showUpdateModal}
            setShowUpdateModal={setShowUpdateModal}
            userData={userData}
            setUserListUpdated={setUserListUpdated}
          />
        )} */}
      </div>
    </div>
  )
}

export default HocComponent(Courts);
