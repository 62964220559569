import {GET_TEAMS,SEARCH_TEAM} from "../constant/comman";
import axios from "axios";

export const getTeams = async(AuthToken) =>{
    try {
        return await axios.get(`${GET_TEAMS}`,{
            headers: { Authorization: `Bearer ${AuthToken}` },
        })
    }
    catch (error){
        throw error;
    }
}

export const searchTeam = async(AuthToken) =>{
    try {
        return await axios.get(`${SEARCH_TEAM}`,{
            headers: { Authorization: `Bearer ${AuthToken}` },
        })
    }
    catch (error){
        throw error;
    }
}