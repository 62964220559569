import React from "react";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import HocComponent from "../../HOC";
import { sendNotification } from "../../Graphs/sendNotification";
import {notification} from 'antd';

const Notification = () => {

  const validateForm = (values) => {
    const errors = {};

    if (!values.message) {
      errors.message = "Message is required";
    }

    return errors;
  }

  const handleSubmit = async (value) => {
    console.log("Send Notification", value);
    await sendNotification(value.message)
      .then((res) => {
        console.log("Response", res);
        notification["success"]({
          message: "Notification sended successfully",
        });
      }).catch((err) => {
        console.log("Error in send notification ",err);
        notification["error"]({
          message: "Failed to send notification",
        });
      })
  }

  return (
    <>
      <div className="p-4">
        <div className="top-heading mx-4">
          <div className="d-flex align-items-center justify-content-between">
            <div className="cr-heading">
              <h4 className="mb-3">Notification</h4>
            </div>
          </div>
        </div>

        <div className="notification-container mx-3">
          <Formik
            initialValues={{
              message: "",
            }}
            validate={validateForm}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => {
              return (
                <form action="" className="d-flex">
                  <div className="d-flex align-items-start">
                    <div>
                      <textarea
                        id="message"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        cols="30"
                        rows="10"
                        className="notification-textarea"
                        placeholder="Message..."
                      ></textarea>
                      {errors.message && touched.message ? (
                        <p
                          style={{
                            display: "flex",
                            marginBottom: "24px",
                            color: "red",
                            fontSize: "small",
                            marginLeft: "70px",
                          }}
                        >
                          {errors.message}
                        </p>
                      ) : null}
                    </div>
                    <button type="submit" className="notification-btn ms-3 mt-5" onClick={handleSubmit}>
                      Send<FontAwesomeIcon icon={faPaperPlane} className="bell-icon pl-2" />
                    </button>
                  </div>
                  <div className="">

                  </div>
                </form>
              )
            }}</Formik>
        </div>
      </div>
    </>
  );
};

export default HocComponent(Notification);
