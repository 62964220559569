import React, { useEffect, useState } from 'react';
import HocComponent from '../../HOC';
import { Input, Card, notification } from 'antd';
import { Formik } from "formik";
import { useSelector } from 'react-redux';
import { addNewCourt, uploadImage } from '../../Graphs/addCourt';
import { TimePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import GeoLocation from '../Components/GeoLocation';
const format = 'HH:mm';


const AddCourt = () => {

    const navigate = useNavigate();
    const [addedCourt, setAddedCourt] = useState({});
    const [showMapModal, setShowMapModal] = useState(false);
    const [errorLocation,setErrorLocation] = useState(false);
    const [timeForDisable,setTimeForDisable] = useState()
    const AuthToken = useSelector(
        (state) => state?.auth?.loginUserData?.newToken
    );

    const validateForm = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Name is required";
        } else if (!/[a-zA-Z]+/.test(values.name)) {
            errors.name = "Enter letters only";
        }

        // if (!values.address) {
        //     errors.address = "Address is required";
        // } 
        // else if (!/[a-zA-Z]+/.test(values.address)) {
        //     errors.address = "Enter letters only";
        // }


        if (!values.open_time && !values.close_time) {
            errors.close_time = "Open & close time is required";
        } else if (!values.open_time || !values.close_time) {
            errors.close_time = "Open or close time is missing";
        } else if (!values.close_time) {
            errors.close_time = "Close time is required";
        }

        console.log("Validating errors -- ", errors);

        return errors;
    }

    const handleSubmit = async (values) => {
        const param = {
            ...values,
            address:addedCourt.address,
            lat: addedCourt.lat,
            lng: addedCourt.lng
        }
        if(addedCourt.lat && addedCourt.lng){
            addCourtData(param)
        }else{
            setErrorLocation(true);
            notification["error"]({
                message: "Please select court",
            });
        }

    }

    const addCourtData = async(param) =>{
        try {
            await addNewCourt(param, AuthToken)
                .then((res) => {
                    console.log("Add Court", res);
                    // setAddedCourt(res);
                    notification["success"]({
                        message: "Court added successfully",
                    });
                    navigate("/courts")
                }).catch((err) => {
                    console.log("!! Error in Adding court !!", err);
                })
        } catch (error) {
            console.log("!! Error in Adding court !!", error);
        }
    }

    const handleOpenTimeChange = (e, setFieldValue) => {
        setTimeForDisable(moment(e?._d).hour())
        setFieldValue("open_time", e);
        getDisabledHours();
    }

    const handleCloseTimeChange = (e, setFieldValue) => {
        setFieldValue("close_time", e)
    }

    const handleAddressChange = (e, setFieldValue) => {
        setFieldValue("address", e.target.value)
    }

    const handleMapModal = () => {
        setErrorLocation(false);
        setShowMapModal(true);
    }

    const passDataToParent = (props, address) => {
        setAddedCourt({ lat: props.lat, lng: props.lng, address: address })
    }
    console.log("addedCourt", addedCourt);

    const getDisabledHours = () => {
        var hours = [];
        for(var i =0; i <= timeForDisable; i++){
            hours.push(i);
        }
        return hours;
    }

    return (
        <div>
            <section className="court-section">
                <div className="d-flex justify-content-center align-items-center p-4">
                    <Card style={{ width: 700, height: " 100%", marginTop: "40px", marginBottom: "50px" }} className="d-flex flex-column align-items-center justify-content-cneter lg-conatiner">
                        <div
                            className="d-flex flex-row align-items-center justify-content-center mb-2"
                            style={{ height: "37px" }}
                        >
                            <h2 className="text-uppercase fw-bold fs-4 ms-3">Add Court</h2>
                        </div>
                        <div>
                            {addedCourt && 
                            <Formik
                                initialValues={{
                                    lat: addedCourt?.lat,
                                    long: addedCourt?.lng,
                                    name: "",
                                    address: addedCourt?.address,
                                    open_time: "",
                                    close_time: ""
                                }}
                                validate={validateForm}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    setFieldTouched,
                                    isSubmitting,
                                }) => {
                                    return (
                                        <>
                                            <div className="formik-field_wrap row auth_form d-flex align-items-center">
                                                <div className="formik-field-left col col-lg-6 col-12 auth_field mb-3 text-white">
                                                    {/* <Input
                                                        style={{ padding: "10px 15px" }}
                                                        id="lat"
                                                        // type='number'
                                                        placeholder="Lattitude"
                                                        value={values.lat}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="form-control"

                                                    /> */}
                                                    <button
                                                        type="submit"
                                                        className="reset-password"
                                                        onClick={handleMapModal}
                                                    >
                                                        <i className="fa-solid fa-circle-check me-2"></i>Select Court
                                                    </button>
                                                    {errorLocation  ? (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                fontSize: "small",
                                                                margin: "0",
                                                            }}
                                                        >
                                                            {"Click here to select court"}
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="formik-field-left col col-lg-6 col-12 auth_field mb-3 text-white">
                                                    Name
                                                    <Input
                                                        style={{ padding: "10px 15px" }}
                                                        id="name"

                                                        type='text'
                                                        placeholder="Name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="form-control"

                                                    />
                                                    {errors.name && touched.name ? (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                fontSize: "small",
                                                                margin: "0",
                                                            }}
                                                        >
                                                            {errors.name}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="formik-field_wrap row auth_form">

                                                <div className="formik-field-left col col-lg-12 col-12 auth_field mb-5 text-white">
                                                    Address
                                                    <Input
                                                        style={{ padding: "10px 15px" }}
                                                        id="address"
                                                        placeholder="Address"
                                                        value={addedCourt?.address}
                                                        onChange={(e)=>handleAddressChange(e,setFieldValue)}
                                                        // onBlur={handleBlur}
                                                        className="form-control"

                                                    />
                                                    {errors.address ? (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                fontSize: "small",
                                                                margin: "0",
                                                            }}
                                                        >
                                                            {errors.address}
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="formik-field_wrap row auth_form d-flex justify-content-center">
                                                <div className="formik-field-left col col-lg-6 col-12 auth_field mb-4 text-white">
                                                    &nbsp;  Court time
                                                    <div>
                                                        <TimePicker
                                                            id="open_time"
                                                            value={values.open_time}
                                                            placeholder="00:00"
                                                            allowClear={false}
                                                            // defaultValue={dayjs('12:08', format)}
                                                            format={format}
                                                            onSelect={(e) => handleOpenTimeChange(e, setFieldValue)}
                                                            onBlur={handleBlur}
                                                            className="timePicker"
                                                        />
                                                        &nbsp; To &nbsp;
                                                        <TimePicker
                                                            id="close_time"
                                                            value={values.close_time}
                                                            placeholder="00:00"
                                                            allowClear={false}
                                                            disabledHours={getDisabledHours}
                                                            format={format}
                                                            onSelect={(e) => handleCloseTimeChange(e, setFieldValue)}
                                                            onBlur={handleBlur}
                                                            className="timePicker"
                                                            disabled={!timeForDisable ? true : false}
                                                        />
                                                        {errors.open_time || touched.open_time && errors.close_time || touched.close_time ? (
                                                            <p
                                                                style={{
                                                                    color: "red",
                                                                    fontSize: "small",
                                                                    margin: "0",
                                                                }}
                                                            >
                                                                {errors.close_time}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col col-12 text-center">
                                                <button
                                                    type="submit"
                                                    className="reset-password"
                                                    onClick={handleSubmit}
                                                >
                                                    <i className="fa-solid fa-circle-check me-2"></i>Submit
                                                </button>
                                            </div>
                                        </>
                                    );
                                }}
                            </Formik>
}
                            {showMapModal &&
                                <GeoLocation
                                    showMapModal={showMapModal}
                                    setShowMapModal={setShowMapModal}
                                    passDataToParent={passDataToParent}
                                />
                            }
                        </div>
                    </Card>
                </div>
            </section>
        </div>
    )
}

export default HocComponent(AddCourt);
