import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.min.css';
import './Assets/Css/style.css';
import { BrowserRouter } from 'react-router-dom';
import Router from './Pages/router';
import axios from 'axios';
import setupAxios from "./Graphs/axios";
// import { ScrollContext } from 'react-router-scroll-4';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';

setupAxios(axios, store);

function App() {
  return (
    <div className="App">
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    {/* <ScrollContext> */}
                        <Router />
                    {/* </ScrollContext> */}
                </BrowserRouter>
            </Provider>
        </div>
  );
}

export default App;
