import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import HocComponent from "../../HOC";
import { useSelector } from "react-redux";
import UpdateUser from "../Components/updateUser";
import { deleteUsers } from "../../Graphs/getUser";
import { getUsers } from "../../Graphs/getUser";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { setUser } from "../../actions/users";
import { useNavigate } from "react-router-dom";
import { Modal, notification } from "antd";
import ReactPaginate from "react-paginate";

const Users = () => {
  const UserData = useSelector((state) => state?.user?.users);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState(UserData);
  const [userListUpdated, setUserListUpdated] = useState(false);

  const [currentUsers, setCurrentUsers] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [usersOffset, setUsersOffset] = useState(0);
  const { confirm } = Modal;
  const AuthToken = useSelector(
    (state) => state?.auth?.loginUserData?.newToken
  );

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % users.length;
    setUsersOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = usersOffset + 10;
    setCurrentUsers(users.slice(usersOffset, endOffset));
    setPageCount(Math.ceil(users.length / 10));
  }, [users, usersOffset]);

  const handleUpdateMOdal = (data) => {
    setShowUpdateModal(true);
    setUserData(data);
  };

  // DELETE USER API
  const handleDelete = (Id) => {
    deleteUsers(AuthToken, Id)
      .then((res) => {
        getUserData();
        notification["success"]({
          message: "User Deleted Successfully",
        });
      })
      .catch((e) => {
        console.log("!!! Error in delete user !!!", e);
        dispatch(logout());
        navigate(`${process.env.PUBLIC_URL}/`);
        return e;
      });
  };

  // GET UPDATED USER LIST
  const getUserData = async () => {
    await getUsers(AuthToken)
      .then((res) => {
        dispatch(setUser(res));
        setUsers(res);
      })
      .catch((e) => {
        console.log("!!! Error in getUser !!!", e);
        dispatch(logout());
        navigate(`${process.env.PUBLIC_URL}/`);
        return e;
      });
  };

  const onSetvalues = (e) => {
    const data = UserData.filter((ele, index) => {
      return ele.phone_number.toString().includes(e.target.value);
    });
    setUsers(data);
  };

  const showDeleteConfirm = (Id) => {
    confirm({
      title: "Are you sure! you want to delete this record?",
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: "Delete",

      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        console.log("OK");
        handleDelete(Id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    getUserData();
  }, [userListUpdated]);

  return (
    <>
      <div className="my-4">
        <div className="top-heading mx-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="cr-heading">
              <h4>List of Users</h4>
            </div>
            <div className="d-flex align-items-center justify-content-between position-relative">
              <FontAwesomeIcon icon={faSearch} className="searchIcon-small" />
              <input
                type="text"
                className="search-input-team mx-2"
                placeholder="Search..."
                onChange={(e) => onSetvalues(e)}
              />
            </div>
          </div>
        </div>

        <div className="list-table mx-3">
          {currentUsers && currentUsers.length >= 1 ? (
            <table className="w-100">
              <thead>
                <tr>
                  {/* <th className="d-flex align-items-center">
                  <input type="checkbox" className="form-check-input" />
                  <Checkbox
                    indeterminate={checkedList.length < userData.length && checkedList.length > 0}
                    onChange={onCheckAllChange}
                    checked={checkedList.length === userData.length}
                  >
                    Check all
                  </Checkbox>
                  <span className="ms-3">All</span>
                </th> */}
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  currentUsers.map((data) => {
                    return (
                      <>
                        <tr key={data?._id}>
                          {/* <td>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            className="form-check-input"
                          />
                          <Checkbox option={data}
                           value={checkedList} onChange={onChange}>
                            {}
                          </Checkbox>
                        </td> */}
                          <td>
                            <div className="d-flex align-items-center ">
                              <img
                                src={data?.profile_picture}
                                alt=""
                                className="profile-icon rounded-circle"
                              />
                              <span className="ms-2">
                                {data?.full_name && data?.full_name.split(" ")[0]}
                              </span>
                            </div>
                          </td>
                          <td>
                            {data?.full_name && data?.full_name.split(" ")[1]}
                          </td>
                          <td className="user-email">{data?.email}</td>
                          <td>{data?.phone_number}</td>
                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                className="cr-md-btn"
                                onClick={() => handleUpdateMOdal(data)}
                              >
                                Update
                              </button>
                              <button
                                className="cr-del-btn ms-2"
                                onClick={() => showDeleteConfirm(data?._id)}
                              >
                                {" "}
                                <FontAwesomeIcon icon={faTrash} className="" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                }
              </tbody>
            </table>
          ) : (
            <h2 style={{ marginTop: "20px" }}>Users Not Found</h2>
          )}
          {/* {currentUsers && currentUsers.length >= 5 ? ( */}
          <div className="pagination justify-content-end">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<<"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
          {/* ) : null} */}
        </div>

        {showUpdateModal && (
          <UpdateUser
            showUpdateModal={showUpdateModal}
            setShowUpdateModal={setShowUpdateModal}
            userData={userData}
            setUserListUpdated={setUserListUpdated}
          />
        )}
      </div>
    </>
  );
};

export default HocComponent(Users);
