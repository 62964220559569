import {LOCATION_DATA} from "../constant/comman";
import axios from "axios";

export const getLocationData = async (AuthToken) => {
    try {
        return await axios.get(`${LOCATION_DATA}`, {
        headers: { Authorization: `Bearer ${AuthToken}` },
        });
      
    }
    catch (error) {
      throw error;
    }
  };