import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BellOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Chart from "react-apexcharts";
import { NavLink } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import HocComponent from "../../HOC/index";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getUsers, usersByYear } from "../../Graphs/getUser";
import { useSelector } from "react-redux";
import { getReports } from "../../Graphs/getReports";
import { useDispatch } from "react-redux";
import { setReports } from "../../actions/reports";
import { getTeams } from "../../Graphs/getTeams";
import { setTeams } from "../../actions/teams";
import { setUser } from "../../actions/users";
import { logout } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import { deleteUsers } from "../../Graphs/getUser";
import { notification, Spin, Modal } from "antd";
import UpdateUser from "../Components/updateUser";
import vectorImg from "../../Assets/Images/Vector.png";
import { setCourts } from "../../actions/courts";
import { getCourts } from "../../Graphs/addCourt";
import placeholderImage from "../../Assets/Images/placeholder.jpg";
// import io from "socket.io-client";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);

  // const socket = io();

  const { confirm } = Modal;
  const years = [];
  let i = 0;
  const currentYear = new Date().getFullYear();
  console.log("currentYear", currentYear);
  while (i < 5) {
    years.push(currentYear - i);
    i++;
  }
  const [usersTotal, setUsersTotal] = useState();
  const [active, setActive] = useState(currentYear);
  const [userListUpdated, setUserListUpdated] = useState(false);

  const AuthToken = useSelector(
    (state) => state?.auth?.loginUserData?.newToken
  );
  const Reports = useSelector((state) => state?.reports?.reports);
  const Teams = useSelector((state) => state?.teams?.teams);
  const Users = useSelector((state) => state?.user?.users);
  const Admin = useSelector((state) => state?.auth?.loginUserData?.user);
  const lastId = Reports[Reports.length - 1];

  // Get Users API Call
  const getUserData = async () => {
    const temparray = [];
    let yeardate = '';
    setLoadingUsers(true);
    await getUsers(AuthToken)
      .then((res) => {
        dispatch(setUser(res));
        res.map((item, index) => {
          const date = new Date(item.createdAt);
          yeardate = date.getFullYear();
          const getmonthdigit = date.getMonth();
          temparray.push(getmonthdigit);

        });
        setLoadingUsers(false);
      })
      .catch((e) => {
        console.log("!!! Error in getUser !!!", e);
        dispatch(logout());
        navigate(`${process.env.PUBLIC_URL}/`);
        setLoadingUsers(false);
        return e;
      });
  };


  // get Graph data 
  const getGraphDataByYear = async (year) => {
    console.log("year", year);
    await usersByYear(AuthToken, year)
      .then((res) => {
        console.log("Yearly data", res);
        setUsersTotal(res?.users)
      }).catch((err) => {
        console.log("!! Error in get yearly data !!", err);
      })
  }

  // getCourts

  const getallCourts = async () => {
    await getCourts(AuthToken)
      .then((res) => {
        console.log("response", res);
        dispatch(setCourts(res));
        // getPaginated(res);
      }).catch((err) => {
        console.log("!! Error in get Courts !!", err);
      })
  }

  // GET REPORTS
  const getReportData = async () => {
    setLoadingReports(true);
    await getReports(AuthToken)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setReports(res.reports));
        }
        setLoadingReports(false);
      })
      .catch((e) => {
        console.log("!!! Error in getReport !!!", e);
        dispatch(logout());
        navigate(`${process.env.PUBLIC_URL}/`);
        setLoadingReports(false);
        return e;
      });
  };

  // GET TEAMS
  const getTeamsData = async () => {
    try {
      setLoadingTeams(true);
      await getTeams(AuthToken)
        .then((res) => {
          if (res) {
            dispatch(setTeams(res));
          }
          setLoadingTeams(false);
        })
        .catch((e) => {
          console.log("!!! Error in getTeams !!!", e);
          dispatch(logout());
          navigate(`${process.env.PUBLIC_URL}/`);
          setLoadingTeams(false);
          return e;
        });
    } catch (error) {
      console.log("!!! Error in getTeams !!!", error);
    }
  };

  // DELETE USER
  const handleDelete = (Id) => {
    deleteUsers(AuthToken, Id)
      .then((res) => {
        notification["success"]({
          message: "User Deleted Successfully",
        });
        getUserData();
      })
      .catch((e) => {
        console.log("!!! Error in delete user !!!", e);
        dispatch(logout());
        navigate(`${process.env.PUBLIC_URL}/`);

        return e;
      });
  };

  // HANDLE USER UPDATE MODAL
  const handleUpdateMOdal = (data) => {
    setShowUpdateModal(true);
    setUserData(data);
  };

  const handleYear = (e, Id) => {
    getGraphDataByYear(e.target.innerText)
    setActive(e.target.innerText);
  };

  const showDeleteConfirm = (Id) => {
    confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Delete',

      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        console.log('OK');
        handleDelete(Id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  useEffect(() => {
    getReportData();
    getTeamsData();
    getUserData();
    getallCourts();
    getGraphDataByYear(new Date().getFullYear());
  }, []);

  // useEffect(() => {

  //   yearData();
  // }, [searchYear]);

  useEffect(() => {
    getUserData();
  }, [userListUpdated]);

  return (
    <>
      <div className="top-heading">
        <div className="d-flex align-items-center justify-content-between mx-3 mt-4">
          <div className="cr-heading pt-2">
            <h4>
              Hello {Admin?.full_name} !{" "}
              <span className="d-flex align-items-center ms-3">
                {" "}
                <img src={vectorImg} alt="vawimg hand" />
              </span>
            </h4>
            <p>Welcome back and explore the dashboard.</p>
          </div>
          {/* <div className="d-flex justify-content-between position-relative">
            <FontAwesomeIcon icon={faSearch} className="searchIcon" />
            <input
              type="text"
              className="search-input mx-3"
              placeholder="search"
            />
            <button className="home-serch-btn">Search</button>
          </div> */}
          {/* <div className="Notification-icon">
            <div className="nofity-dot"></div>
            <BellOutlined className="bell-icon" />
          </div> */}
        </div>
      </div>

      <div className="m-3">
        <div className="row">
          <div className="col-6">
            <div className="cr-card">
              <div className="line-chart py-2">
                <div className="d-flex align-items-center justify-content-center">
                  {years &&
                    years.slice(0).reverse().map((year, index) => {
                      return (
                        <button
                          key={index}
                          className={`chart-btn ${active == year ? "active" : ""
                            }`}
                          value={year}
                          onClick={(e) => handleYear(e, index)}
                        >
                          {year}
                        </button>
                      );
                    })}
                </div>
                <Chart
                  options={{
                    colors: ["#E7BF49", "#E7BF49"],
                    stroke: {
                      curve: "smooth",
                      colors: ["#E7BF49", "#E7BF49"],
                    },

                    chart: {
                      toolbar: {
                        show: false,
                      },
                      id: "apexchart-example",
                    },
                    dataLabels: {
                      enabled: false,
                    },

                    stroke: {
                      curve: "smooth",
                      colors: ["#E7BF49", "#E7BF49"],
                    },

                    xaxis: {
                      categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "June",
                        "July",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ],
                    },
                  }}
                  series={[
                    {
                      name: "Users Added",
                      data: usersTotal,
                    },
                  ]}
                  type="area"
                  width={"100%"}
                />
              </div>
            </div>
            <div
              className="cr-card my-4 team-card"
            >
              <div className="d-flex align-items-center justify-content-between my-2 list">
                <h5 className="cr-card-title">List of Teams</h5>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/teams`}
                  className="see-all-btn"
                >
                  See All <FontAwesomeIcon icon={faChevronRight} />
                </NavLink>
              </div>
              {loadingTeams ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  {" "}
                  <Spin />{" "}
                </div>
              ) : Teams && Teams.length >= 1 ? (
                <table className="w-100 dashboard-report-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="p-1 ">Name</th>
                      <th className="p-1 text-center">Teams</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Teams.map((data, index) => {
                      return (
                        <>
                          {index <= 2 &&
                            <tr key={data?.team?._id}>
                              <td className="p-0">
                                {" "}
                                <img
                                  src={data?.team?.profile_image !== null ? data?.team?.profile_image : placeholderImage}
                                  className="team-logo"
                                />
                              </td>
                              <td className="p-0 py-2">
                                <h5>{data?.team?.title}</h5>
                                <span>{data?.createdAt}</span>
                              </td>
                              <td className="p-0 py-2">
                                <div className="row">
                                  <ul className="users col-12 ">
                                    {data?.teamMembers &&
                                      data?.teamMembers.map((item) => {
                                        return (
                                          <>
                                            <li key={item?._id}>
                                              <img src={item?.profile_picture} />
                                            </li>
                                          </>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          }
                        </>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <h2 className="text-light">Teams not found</h2>
              )}
            </div>
          </div>
          <div className="col-6">
            <div
              className="cr-card user-card"
            >
              <div className="d-flex align-items-center justify-content-between list">
                <h5 className="cr-card-title">List of Users</h5>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/users`}
                  className="see-all-btn"
                >
                  See All <FontAwesomeIcon icon={faChevronRight} />
                </NavLink>
              </div>

              <div className="userList">
                <ul className="cr-heading">
                  {loadingUsers ? (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "15px",
                      }}
                    >
                      <Spin />
                    </div>
                  ) : Users && Users.length >= 1 ? (
                    Users.map((data, index) => {
                      return (
                        <>
                          {index < 5 && (
                            <li
                              className="d-flex align-items-center justify-content-between"
                              key={data?._id}
                            >
                              <div className="usr-info d-flex">
                                <img
                                  src={data?.profile_picture}
                                  alt=""
                                  // srcset=""
                                  className="profile-icon"
                                />
                                <div className="ms-3">
                                  <h6>{data?.full_name}</h6>
                                  <span>{data?.email}</span>
                                </div>
                              </div>
                              <div className="action-btn">
                                <button
                                  className="cr-md-btn"
                                  onClick={() => handleUpdateMOdal(data)}
                                >
                                  Update
                                </button>
                                <button
                                  className="cr-del-btn ms-3"
                                  onClick={() => showDeleteConfirm(data?._id)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <h2 className="text-light">Users not found</h2>
                  )}
                </ul>
              </div>
            </div>

            <div
              className="cr-card mt-4 report-card"
            >
              <div className="d-flex align-items-center justify-content-between my-3 list">
                <h5 className="cr-card-title">Report</h5>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/reports/${lastId?.report?._id}`}
                  className="see-all-btn"
                >
                  See All <FontAwesomeIcon icon={faChevronRight} />
                </NavLink>
              </div>

              <div className="userList">
                <ul className="cr-heading">
                  {loadingReports ? (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "15px",
                      }}
                    >
                      {" "}
                      <Spin />{" "}
                    </div>
                  ) : Reports && Reports.length >= 1 ? (
                    Reports.map((data, index) => {
                      return (
                        <>
                          {index < 2 && (
                            <li
                              className="d-flex align-items-center justify-content-between mt-3"
                              key={data?.report?._id}
                            >
                              <div className="usr-info d-flex">
                                <img
                                  src={data?.sender?.profile_picture}
                                  alt=""
                                  // srcset=""
                                  className="profile-icon"
                                />
                                <div className="ms-3">
                                  <h6>{data?.sender?.full_name}</h6>
                                  <span>{data?.sender?.email}</span>
                                </div>
                              </div>
                              <div className="report-action action-btn">
                                <button
                                  className="cr-md-btn"
                                  onClick={() =>
                                    navigate(
                                      `${process.env.PUBLIC_URL}/reports/${data?.report?._id}`
                                    )
                                  }
                                >
                                  View <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <h2 className="text-light">Reports not found</h2>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {showUpdateModal && (
          <UpdateUser
            showUpdateModal={showUpdateModal}
            setShowUpdateModal={setShowUpdateModal}
            userData={userData}
            setUserListUpdated={setUserListUpdated}
          />
        )}
      </div>
    </>
  );
};

export default HocComponent(Dashboard);
