import React from 'react';
import { Input, Card ,notification} from 'antd';
import { Formik } from "formik";
import { useParams } from 'react-router';
import { ChangePassword } from '../../Graphs/password';

const ForgotPassword = () => {
  const param = useParams();
  console.log("Params Id",param.id);
    const validateForm = (values) => {
        const errors = {};
        if (!values.password) {
            errors.password = "password is required";
        } else if (
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&^])[A-Za-z\d@#$!%*?&^]{8,}$/.test(
                String(values.password)
            )
        ) {
            errors.password =
                "At least 8 characters with One Capital,One Special& One number is required";
        }
        if (!values.confirm_password) {
            errors.confirm_password = "Confirm Password is required";
        }
        if (values.password !== values.confirm_password) {
            errors.confirm_password = "Passwords do not match";
        }
        console.log("Validating errors -- ", errors);

        return errors;
    }

    const handleSubmit = async(values) => {
        values._id = param.id
        await ChangePassword(values)
        .then((res) => {
            if (res.status === 1) {
                notification["success"]({
                    message: "Password has been updated successfully",
                }
                );
                // setForgotPwdModalVisible(false)
            }

        }).catch((err) => {
            notification["error"]({
                message: "Something went wrong!",
            });
        })
    }
    return (
        <section className="lg-conatiner">
            <div className="d-flex justify-content-center align-items-center p-4 lg-conatiner">
                <Card style={{ width: 700, height: 414, marginTop: "40px", marginBottom: "50px" }} className="d-flex flex-column align-items-center justify-content-cneter lg-conatiner">
                    <div
                        className="d-flex flex-row align-items-center justify-content-center"
                        style={{ height: "37px" }}
                    >
                        <div className="bs-icon-md bs-icon-circle bs-icon-primary bs-icon my-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2em"
                                height="2em"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                className="bi bi-person"
                            >
                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
                            </svg>
                        </div>
                        <h2 className="text-uppercase fw-bold fs-4 ms-3">Reset Password</h2>
                    </div>
                    <div>
                        <Formik
                            initialValues={{
                                password: "",
                                confirm_password: ""
                            }}
                            validate={validateForm}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched,
                                isSubmitting,
                            }) => {
                                return (
                                    <>
                                        <div className="formik-field_wrap row auth_form">
                                            <div className="formik-field-left col col-lg-12 col-12 auth_field mb-3 text-white">
                                                Password
                                                <Input
                                                    style={{ padding: "10px 15px" }}
                                                    id="password"
                                                    type="password"
                                                    placeholder="Password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control"

                                                />
                                                {errors.password && touched.password ? (
                                                    <p
                                                        style={{
                                                            color: "red",
                                                            fontSize: "small",
                                                            margin: "0",
                                                        }}
                                                    >
                                                        {errors.password}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="formik-field-left col col-lg-12 col-12 auth_field mb-5 text-white">
                                                Confirm Password
                                                <Input
                                                    style={{ padding: "10px 15px" }}
                                                    id="confirm_password"
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control"

                                                />
                                                {errors.confirm_password && touched.confirm_password ? (
                                                    <p
                                                        style={{
                                                            color: "red",
                                                            fontSize: "small",
                                                            margin: "0",
                                                        }}
                                                    >
                                                        {errors.confirm_password}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col col-12 text-center">
                                            <button
                                                type="submit"
                                                className="reset-password"
                                                onClick={handleSubmit}
                                            >
                                                <i className="fa-solid fa-circle-check me-2"></i>Submit
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Formik>
                    </div>
                </Card>
            </div>
        </section>
    )
}

export default ForgotPassword
