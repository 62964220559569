import { LOGOUT } from "../../constant/comman";
import axios from "axios";

export const adminLogout = async (AuthToken) => {
  try {
      return await axios.get(`${LOGOUT}`, {
        headers: { Authorization: `Bearer ${AuthToken}` },
      });
    
  }
  catch (error) {
    throw error;
  }
};