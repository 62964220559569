import React, { useEffect, useState } from "react";
import HocComponent from "../../HOC";
import { getLocationData } from "../../Graphs/getLocationData";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import ReactPaginate from "react-paginate";

const Location = () => {
  const [locationData, setLocationData] = useState([]);
  const [address, setAddress] = useState();
  const [loadingLocation, setLoadingLocation] = useState(false);

  const [currentUsers, setCurrentUsers] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [usersOffset, setUsersOffset] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthToken = useSelector(
    (state) => state?.auth?.loginUserData?.newToken
  );

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 2) % locationData.length;
    setUsersOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = usersOffset + 5;
    setCurrentUsers(locationData.slice(usersOffset, endOffset));
    setPageCount(Math.ceil(locationData.length / 5));
  }, [locationData, usersOffset]);

  const getLocationDetails = async () => {
    setLoadingLocation(true);
    await getLocationData(AuthToken)
      .then((res) => {
        if (res) {
          setLocationData(res);
        }
        setLoadingLocation(false);
      })
      .catch((e) => {
        console.log("!!! Error in getLocation !!!", e);
        dispatch(logout());
        navigate(`${process.env.PUBLIC_URL}/`);
        setLoadingLocation(false);
        return e;
      });
  };

  useEffect(() => {
    getLocationDetails();
  }, []);
  return (
    <div className="my-4">
      <div className="top-heading mx-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="cr-heading">
            <h4>Location Details</h4>
          </div>
        </div>
      </div>
      <div className="list-table mx-3 location-table">
        {loadingLocation ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "25px",
            }}
          >
            <Spin />{" "}
          </div>
        ) : (
          <>
            <table className="w-100">
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Members</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers &&
                  currentUsers.map((data) => {
                    // const reverseGeocodingUrl = `https://api.geoapify.com/v1/geocode/reverse?lat=${data?.locationInfo?.location[0]}&lon=${data?.locationInfo?.location[1]}&apiKey=d6d98f7a399143bd949c60f4f8b33af2`;
                    // // const address='';
                    // fetch(reverseGeocodingUrl)
                    //   .then((result) => result.json())
                    //   .then((featureCollection) => {
                    //     setAddress(
                    //       featureCollection.features[0].properties.formatted
                    //     );
                    //   });
                    return (
                      <>
                        <tr>
                          <td>
                            <div>{data?.address}</div>
                          </td>
                          <td className="team-date">{data?.date}</td>
                          <td>
                          {data?.time[0]} -{" "}
                          {data?.time[1]}
                        </td>
                          <td>{data?.totalplayers}</td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {currentUsers && currentUsers.length >= 5 ? (
              <div className="pagination d-flex justify-content-end">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">>"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<<"
                  renderOnZeroPageCount={null}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default HocComponent(Location);
