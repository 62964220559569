import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import teams from './teams';
import reports from './reports';
import courts from './courts';
import { USER_LOGOUT } from '../constant/actionTypes';

const reducers = combineReducers({
    auth,
    user,
    teams,
    reports,
    courts,
});

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        state = undefined;
    }
  
    return reducers(state, action);
};

export default rootReducer;