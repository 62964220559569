import React, { useEffect, useState } from "react";
import { Layout, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppstoreFilled, UnorderedListOutlined } from "@ant-design/icons";
import { faThLarge } from "@fortawesome/free-solid-svg-icons";
import logo from "../Assets/Images/cr-logo 1.png";
import { useSelector, useDispatch } from "react-redux";
import { adminLogout } from "../Graphs/Auth/logout";
import { NavLink } from "react-router-dom";
import { logout } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import { setToggle } from "../actions/toggleBtn";

const { Sider } = Layout;

const Sidebar = (props) => {
  console.log(props);

  const AuthToken = useSelector(
    (state) => state?.auth?.loginUserData?.newToken
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ReportData = useSelector((state) => state?.reports?.reports);

  // dispatch(setToggle(props.data));

  const lastId = ReportData[ReportData.length - 1];
  function openNav() {
    console.log("Opean sidebar");
    document.getElementById("sidebar-panel").style.width = "318px";
    // document.getElementById("align-component").style.paddingLeft = "318px";
  }

  function closeNav() {
    console.log("Close sidebar");
    document.getElementById("sidebar-panel").style.width = "50px";
    // document.getElementById("align-component").style.paddingLeft= "50px";
  }

  const handleLogout = async () => {
    await adminLogout(AuthToken)
      .then((res) => {
        if (res) {
          dispatch(logout());
          navigate(`${process.env.PUBLIC_URL}/`);
          notification["success"]({
            message: "Admin Logout Successfully",
          });
        }
      })
      .catch((e) => {
        console.log("!! Error in Logout API !!",e.response);
        dispatch(logout());
        navigate(`${process.env.PUBLIC_URL}/`);
        return e;
      });
  };

  const [toggleBar, setToggleBar] = useState(false);

  const setToogleAction = (toggle) => {
    console.log(toggle);
    setToggleBar(true);
    dispatch(toggleBar);
  };
  console.log(toggleBar);

  return (
    <aside
      className={
        props.data
          ? "sidebar-panel active bg-black sm:max-w-xs lg:w-80 pt-1"
          : "sidebar-panel bg-black sm:max-w-xs lg:w-80 pt-1"
      }
      aria-label="Sidebar"
      // style={{width: props.data? '120px': "320px",transition: "all linear 0.3s"}}
    >
      {/* <div className="toggle-btn" onClick={()=>setToggleBar(!toggleBar)}>toggle bar</div> */}
      {/* <div className="toggle-btn" >Toggle button</div> */}

      <div className="align-logo flex justify-between">
        <div className="flex flex-row">
          <img
            alt="logo"
            src={logo}
            width="84px"
            height="73px"
            className="cr-logo1"
          />
          <span
            className="flex items-center head-text pl-3 pt-3"
            style={{ display: props.data ? "none" : "block" }}
          >
            Court Royale
          </span>
          {/* <button className="text-white" onClick={openNav}>{`>`}</button>
            <button className="text-white" onClick={closeNav}>{`<`}</button> */}
        </div>
      </div>
      <div className="pt-6 sidebar-height flex flex-col justify-between ">
        <div className="side-link p-3">
          <ul className="space-y-2">
            <li className={props.data ? "side-list toggle" : "side-list "}>
              <NavLink
                to={`${process.env.PUBLIC_URL}/dashboard`}
                className="flex items-center justify-start text-base py-3 font-normal text-gray-900 rounded-lg"
              >
                <AppstoreFilled />
                {/* <i className="fa fa-th-large w-6 h-6" aria-hidden="true"></i> */}

                <span className="ml-3 link-name">Dashboard</span>
              </NavLink>
            </li>
            {/* <li className="side-list">
                <NavLink
                  to="/users"
                  className="flex items-center justify-start text-base py-3 font-normal text-gray-900 rounded-lg"
                >
                  <i className="fa fa-th-list w-6 h-6 text-gray-500 dark:text-gray-400" aria-hidden="true"></i>
                  <span className="ml-3">
                    List of Users
                  </span>
                </NavLink>
              </li> */}
            <li className={props.data ? "side-list toggle" : "side-list "}>
              <NavLink
                to={`${process.env.PUBLIC_URL}/teams`}
                className="flex items-center justify-start text-base py-3 font-normal text-gray-900 rounded-lg"
              >
                {/* <i className="fa fa-th-list w-6 h-6 text-gray-500 dark:text-gray-400" aria-hidden="true"></i> */}
                <UnorderedListOutlined />
                <span className="ml-3 link-name">List of Teams</span>
              </NavLink>
            </li>
            <li className={props.data ? "side-list toggle" : "side-list "}>
              <NavLink
                to={`${process.env.PUBLIC_URL}/reports/${lastId?.report?._id}`}
                className="flex items-center justify-start text-base py-3 font-normal text-gray-900 rounded-lg"
              >
                <i
                  className="fa fa-line-chart w-6 h-6 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                ></i>
                <span className="ml-3 link-name">Report</span>
              </NavLink>
            </li>
            <li className={props.data ? "side-list toggle" : "side-list "}>
              <NavLink
                to={`${process.env.PUBLIC_URL}/location`}
                className="flex items-center justify-start text-base py-3 font-normal text-gray-900 rounded-lg"
              >
                <i
                  className="fa fa-map-marker w-6 h-6 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                ></i>
                <span className="ml-3 link-name">Location</span>
              </NavLink>
            </li>
            <li className={props.data ? "side-list toggle" : "side-list "}>
              <NavLink
                to={`${process.env.PUBLIC_URL}/notification`}
                className="flex items-center justify-start text-base py-3 font-normal text-gray-900 rounded-lg"
              >
                <i
                  className="fa fa-bell w-6 h-6 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                ></i>
                <span className="ml-3 link-name">Notification</span>
              </NavLink>
            </li>
            <li className={props.data ? "side-list toggle" : "side-list "}>
              <NavLink
                to={`${process.env.PUBLIC_URL}/courts`}
                className="flex items-center justify-start text-base py-3 font-normal text-gray-900 rounded-lg"
              >
                <i
                  className="fa-solid fa-dungeon w-6 h-6 text-gray-500 dark:text-gray-400"
                  aria-hidden="trfa-solid fa-dungeonue"
                ></i>
                <span className="ml-3 link-name">Courts</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="p-3">
          <ul className="logout-btn pt-4 mt-4 space-y-2 border-t border-gray bottom-0">
            <li className={props.data ? "side-list toggle" : "side-list "}>
              <NavLink
                to=""
                className=" logout-btn flex items-center justify-start text-base py-3 font-normal text-gray-900 rounded-lg transition duration-75 dark:hover:bg-gray-700 dark:text-white group"
                onClick={handleLogout}
              >
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                <span className="ml-4 link-name">Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
