import axios from "axios";
import { USER_RESET_PASSWORD } from "../constant/comman";

export const ChangePassword = async (value) => {
    try{
        return await axios.put(`${USER_RESET_PASSWORD}`,{ 
            _id:value?._id,
            newPassword:value?.password
        },);
    }
    catch(error){
        throw error;
    }
}