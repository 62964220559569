import { COURT_DATA } from "../constant/actionTypes";

const initial_state = {
  courts: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case COURT_DATA:
      return { ...state, courts: action.data };

    default:
      return { ...state };
  }
};