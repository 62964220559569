import { SET_TEAMS } from "../constant/actionTypes";

const initial_state = {
  teams: [],
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_TEAMS:
      return { ...state, teams: action.data };

    default:
      return { ...state };
  }
};
