import { GET_USER, UPDATE_USER ,DELETE_USER, SERCH_USER,USER_LISTING, GRAPH_DATA} from "../constant/comman";
import axios from "axios";

export const getUsers = async (AuthToken) => {
  try {
    return await axios.get(`${GET_USER}`, {
      headers: { Authorization: `Bearer ${AuthToken}` },
    });
  } catch (error) {
    throw error;
  }
};

export const userList = async (AuthToken) =>{
  try {
    return await axios.get(`${USER_LISTING}`, {
      headers: { Authorization: `Bearer ${AuthToken}` },
    });
  } catch (error) {
    throw error;
  }
}

export const updateUsers = async (AuthToken, Id, data) => {
  const fullName = `${data?.first_name} ${data?.last_name}`;
  try {
    return await axios.put(
      `${UPDATE_USER}/${Id}`,
      {
        full_name: fullName,
        email: data.email,
        phone_number: data.phone,
      },
      {
        headers: { Authorization: `Bearer ${AuthToken}` },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const deleteUsers = async (AuthToken,Id) => {
  try {
    return await axios.delete(`${DELETE_USER}/${Id}`, {
      headers: { Authorization: `Bearer ${AuthToken}` },
    });
  } catch (error) {
    throw error;
  }
};

export const serachUser = async(AuthToken) =>{
  try {
    return await axios.get(`${SERCH_USER}`,{
      headers: {Authorization: `Bearer ${AuthToken}` },
    });
  } catch (error) {
    throw error;
  }
}

export const usersByYear = async(AuthToken,year) =>{
  try {
    return await axios.get(`${GRAPH_DATA}?year=${year}`,{
      headers: {Authorization: `Bearer ${AuthToken}` },
    });
  } catch (error) {
    throw error;
  }
}


